<template>
    <el-dialog
            :title="title"
            :close-on-click-modal="false"
            append-to-body
            :visible.sync="visible">
        <el-form size="small" :model="inputForm" :rules="dataRule" v-loading="loading"  ref="inputForm" @keyup.enter.native="doSubmit()"
                 label-width="120px" @submit.native.prevent>
            <el-form-item label="目录名：" prop="directoryName">
                <el-input v-model.trim="inputForm.directoryName" placeholder="请输入目录名" maxlength="50"></el-input>
            </el-form-item>
            <el-form-item label="目录编号：" prop="directoryNo">
                <el-input v-model.trim="inputForm.directoryNo" placeholder="请输入目录编号" maxlength="50"></el-input>
            </el-form-item>
            <el-form-item label="排序号" prop="sortNumber">
                <el-input-number  :step="1" :min="1" :max="9999" v-model="inputForm.sortNumber" placeholder="排序号"></el-input-number>
            </el-form-item>
            <el-form-item label="是否发布：" prop="publishState">
                <el-switch v-model="inputForm.publishState" :active-value="0" :inactive-value="1"></el-switch>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
      <el-button size="small" @click="visible = false">取消</el-button>
      <el-button size="small" type="primary" @click="doSubmit()" v-noMoreClick>确定</el-button>
    </span>
    </el-dialog>
</template>

<script>
    export default {
        data () {
            return {
                visible: false,
                loading: false,
                title: '',
                method: '',
                inputForm: {
                    id: '',
                    exhibitionId:"",
                    directoryName: '',
                    directoryNo: '',
                    sortNumber:"",
                    publishState: 0
                },
                dataRule: {
                    directoryName: [
                        {required: true, message: '目录名不能为空', trigger: 'blur'}
                    ]
                }
            }
        },
        methods: {
            init (method, row) {
                this.method = method
                this.inputForm.exhibitionId = ""
                this.inputForm.id = ""
                if (method === 'add') {
                    this.title = `新建目录`
                    this.inputForm.exhibitionId = row
                } else if (method === 'edit') {
                    this.title = '编辑目录'
                }
                this.visible = true
                this.$nextTick(() => {
                    this.$refs['inputForm'].resetFields()
                    if (method === 'edit') { // 修改
                        this.inputForm = this.recover(this.inputForm, row)
                    }
                })

                console.log(this.inputForm);
            },
            // 表单提交
            doSubmit () {
                this.$refs['inputForm'].validate((valid) => {
                    if (valid) {
                        this.loading = true
                        let api = this.api.mana.directorySave
                        let type = "post"
                        if(this.title == '编辑目录'){
                            api = this.api.mana.directoryUpdateById
                            type = "put"
                        }
                        this.$axios(api, this.inputForm, type).then(data => {
                            this.loading = false
                            if (data && data.status) {
                                this.$message.success(data.msg)
                                this.visible = false
                                this.$emit('refreshDataList')
                            }else{
                                this.$message.error(data.msg)
                            }
                        })
                    }
                })
            }
        }
    }
</script>
